/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';

const useGetVulnerabilitiesByApplication = (
  app_name: string = null,
  keys: Array<any> = [],
  params: any = {},
) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: ['vulnerabilities-by-applications', app_name, ...(keys || [])],
    queryFn: () =>
      vulnerabilityApi(`v1/dashboards/vulns_by_application`).get({
        ...params,
        app_name,
      }),
  });

  const data = apiRes?.data;
  const numVulnerabilities: number = data?.count || 0;
  const KEVScore: number = data?.kev_score || 0;

  return { numVulnerabilities, KEVScore, isLoading };
};

export { useGetVulnerabilitiesByApplication };
